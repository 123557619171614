import { ADD_ITEM, LIST_ITEM, REMOVE_ITEM, UPDATE_QUANTITY } from "./OrderTypes"


export const addItem = (payload) => {
    return {
        type: ADD_ITEM,
        payload
    }
} 

export const updateItem = (payload) => {
    return {
        type: UPDATE_QUANTITY,
        payload
    }
}

export const removeItem = (payload) => {
    return {
        type: REMOVE_ITEM,
        payload
    }
}

export const clearList = (payload) => {
    return {
        type: LIST_ITEM,
        payload
    }
}