import React, { useEffect, useState } from 'react'
import axios from 'axios'
import logo from './img/dplfooterlogo.svg'
import fb from './img/facebook.svg'
import insta from './img/instagram.svg'
import email from './img/email.svg'
import wa from './img/whatsapp.svg'
import bz from './img/bzcon.png'
import { useFormik } from 'formik'
import TextField from '@mui/material/TextField'
import * as Yup from 'yup'
import Modal from 'react-modal'
import Contact from './Contact'
import { Link } from 'react-router-dom'

function Footer(props) {

    const validationSchema = Yup.object({
        name: Yup.string().required('Please enter your name'),
        email: Yup.string().email('Please enter a valid email address').required('Please enter your email address'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, onSubmitProps) => {
            const data = { data: { ...values } }
            axios.post(`${props.api}/api/collections/save/subscribers?token=${props.acctok}`, data)
                .then(res => {
                    if (res.status == 200) {
                        setSubmitted('success')
                        submittedMessage()
                    }
                })
                .catch(err => {
                    setSubmitted('failed')
                    submittedMessage()
                    console.log(err);
                })
            // console.log(values);
            onSubmitProps.resetForm()
        }
    })

    const [submitted, setSubmitted] = useState(null)
    const [modal, setModal] = useState(false)
    const [social, setSocial] = useState({
        facebook: '',
        instagram: '',
        whatsapp: '',
        email: ''
    })

    const submittedMessage = () => {
        setTimeout(() => {
            setSubmitted(null)
        }, 4000)
    }

    const closeModal = () => {
        setModal(false)
    }

    const openModal = () => {
        setModal(true)
    }

    const getSocials = () => {
        axios.get(`${props.api}/api/singletons/get/social?token=${props.acctok}`)
            .then(res => {
                setSocial({ facebook: res.data.facebook, instagram: res.data.instagram, whatsapp: res.data.whatsapp, email: res.data.email })
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getSocials()
    }, [])

    return (
        <footer className='row footer-section align-items-center'>
            <div className='col-12 col-md-4 footer-logo-wrapper'>
                <img className='footer-logo' src={logo} alt='dpl footer logo' />
            </div>
            <div className='col-12 col-md-4 footer-section-2'>
                <h2>Get In Touch</h2>
                <ul className='footer-icons'>
                    <li> <a href={`${social.facebook}`}> <img src={fb} /> </a> </li>
                    <li> <a href={`${social.instagram}`}> <img src={insta} /> </a> </li>
                    <li> <a href={`${social.whatsapp}`}> <img src={wa} /> </a> </li>
                    <li> <a href={`mailto:${social.email}`}> <img src={email} /> </a> </li>
                </ul>
                <input type='button' className={`btn btn-footer-message`} value='SEND US A MESSAGE' onClick={() => setModal(true)} />
                <Modal
                    isOpen={modal}
                    closeTimeoutMS={200}
                    contentLabel="modLabel"
                    onRequestClose={() => closeModal()}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <Contact api={props.api} acctok={props.acctok} css_class='contact-form-footer' />
                </Modal>
            </div>
            <div className='col-12 col-md-4 footer-section-3'>
                <div className='text-center footer-subscribe'>
                    <h2>Subscribe</h2>
                    <h5>Subscribe to our mailing list for updates</h5>
                </div>
                <form className='footer-sub-form' onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        name='name'
                        label='Name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        variant='standard'
                    />
                    <TextField
                        fullWidth
                        name='email'
                        label='Email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        variant='standard'
                        className='mt-2'
                    />
                    <div className='text-center mt-3'>
                        {submitted ?
                            submitted === 'success' ? <span className='sbt-success'>Submitted Successfully</span> : <span className='sbt-failure'>Error, please try again later</span> : null}
                    </div>
                    <div className='text-center'>
                        <button type='button' type='submit' className='btn btn-footer-message'>SUBSCRIBE</button>
                    </div>
                    <div className='text-center'>
                        <Link to='/privacy-policy'><p className='policy-link'>Privacy Policy</p></Link>
                    </div>
                </form>
            </div>
            <span className='bz-con'> <a href='https://buzzolution.com/'> <img className='bz-con-logo' src={bz} alt='buzzolution consultancy logo' /><p>Designed and developed by Buzzolution Consultancy</p></a></span>
        </footer>
    )
}

export default Footer