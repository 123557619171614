import './components/css/style.css';
import Home from './components/Home';
import { Provider } from 'react-redux'
import store from './components/redux/Store';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-M89C62H'
}

function App() {
  TagManager.initialize(tagManagerArgs)
  return (
    <Provider store={store}>
      <div className="App">
        <Home />
      </div>
    </Provider>
  );
}

export default App;
