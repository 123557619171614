import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { addItem, removeItem, updateItem } from './redux'
import { connect } from 'react-redux'
import add from './img/add.svg'
import remove from './img/remove.svg'
import { Link, Redirect } from 'react-router-dom'

function Cart(props) {

    useEffect(() => {
        getItem()
    }, [])

    const [items, setItems] = useState()

    const getItem = () => {
        axios.get(`${props.api}/api/collections/get/products?token=${props.acctok}`)
            .then(res => {
                const current = res.data.entries
                setItems(current)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const removeItem = (id) => {
        props.removeItem(id)
    }

    const incrementQuantity = (id) => {
        const quantity = props.orderList.find(x => x.id === id).quantity
        let newQuantity = parseInt(quantity, 10) + 1
        let updateObj = [id, newQuantity]
        props.updateItem(updateObj)
    }

    const drecrementQuantity = (id) => {
        const quantity = props.orderList.find(x => x.id === id).quantity
        let newQuantity = parseInt(quantity, 10) - 1
        let updateObj = [id, newQuantity]
        if (newQuantity >= 1) {
            props.updateItem(updateObj)
        }
    }

    const addToOrder = (id) => {
        const order = {
            id: id,
            quantity: 1
        }
        if (props.orderList.some(e => e.id === order.id)) {
            // console.log('already added');
        } else {
            props.addItem(order)
        }
    }

    const checkOrderList = () => {
        const orderList = props.orderList
        let orderListCount = false
        if (orderList === undefined || orderList.length == 0) {
            orderListCount = true
        } else {
            orderListCount = false
        }
        return orderListCount       
    }

    let cartItems, list, suggestedItems, calcPrice, subtotal

    if (items) {
        list = props.orderList.map(x => (
            x.id
        ))
        // cartItems = items.filter(x => list.includes(x._id))
        cartItems = items.filter(x => list.includes(x._id)).map(x => (
            <div className='col-12 mb-4 cart-col' key={x._id}>
                <div className='row align-items-center cart-row'>
                    <div className='col-12 col-md-1'>
                        <img className='img-fluid' src={`${props.api}/${x.mainImage.path}`} />
                    </div>
                    <div className='col=12 col-md-3'>
                        <Link to={`product/${x._id}`}><p>{x.name}</p> </Link>
                        <p>{x.type.display}</p>
                    </div>
                    <div className='col-12 col-md-2'>
                        <p>Price</p>
                        <p>$ {x.sprice ? x.sprice : x.price}</p>
                    </div>
                    <div className='col-12 col-md-2'>
                        <p>Quantity</p>
                        <img className='quantity remove' src={remove} onClick={() => drecrementQuantity(x._id)} />
                        <p className='quantity-number'>{props.orderList.find(c => c.id === x._id).quantity}</p>
                        <img className='quantity add' src={add} onClick={() => incrementQuantity(x._id)} />
                    </div>
                    <div className='col-12 col-md-2'>
                        <p>Total</p>
                        <p>$ {(parseInt(x.sprice ? x.sprice : x.price, 10)) * (parseInt(props.orderList.find(c => c.id === x._id).quantity))}</p>
                    </div>
                    <div className='col-12 col-md-2'>
                        <input type='button' className={`btn btn-remove`} value='REMOVE' onClick={() => removeItem(x._id)} />
                    </div>
                </div>
            </div>
        ))
    }

    //get max order number
    // sort by order number to 0

    if (items) {
        suggestedItems = items.sort((a, b) => parseInt(a.orders, 10) < parseInt(b.orders, 10) ? 1 : -1).slice(0, 6).map(x => (
            <div key={x._id} className='col-6 col-md-4 col-lg-2 suggested-items'>
                <Link to={`product/${x._id}`}> <img className='img-fluid' src={`${props.api}/${x.mainImage.path}`} /> </Link>
                <Link to={`product/${x._id}`}><p style={{ marginTop: '.2em' }}>{x.name}</p></Link>
                {x.sprice ? <><h3 className='product-price'> <span className='slashed'>$ {x.price}</span> <span className='special'>$ {x.sprice}</span></h3>
                    <p style={{ marginBottom: '.2em' }}> <span className='promo suggested-promo'> PROMOTIONAL PRICE </span></p></>
                    :
                    <h3>$ {x.price}</h3>
                }
                {parseInt(x.quantity) === 0 ?
                    <p className='not-in-stock'>Out of Stock</p>
                    :
                    parseInt(x.quantity) <= parseInt(x.threshold) ? <p className='not-in-stock'>Limited Quantity Available</p> : <p className='in-stock'>In Stock</p>
                }

                <input type='button' className={`btn btn-order suggested-btn`} value={props.orderList.some(e => e.id === x._id) ? 'ALREADY IN ORDER' : 'ADD TO ORDER'} onClick={() => addToOrder(x._id)} disabled={props.orderList.some(e => e.id === x._id) || parseInt(x.quantity) === 0} />
            </div>
        ))
    }

    if (items) {
        calcPrice = items.filter(x => list.includes(x._id)).map(x => (
            (x.sprice ? x.sprice : x.price) * props.orderList.find(c => c.id === x._id).quantity
        ))
        subtotal = calcPrice.reduce((a, b) => a + b, 0)
    }   

    return (
        (checkOrderList()) ? <Redirect to='/products' /> :
            <div className='cart-page'>
                <h1 style={{ textAlign: 'center' }}>Cart</h1>
                <div className='row'>
                    {cartItems ? cartItems
                        : 'Loading...'}
                </div>
                <h2>Subtotal = ${subtotal ? subtotal : null}</h2>
                <Link to='/checkout'><div className='text-center'><input type='button' className={`btn btn-order checkout-btn`} value='PLACE ORDER' /></div></Link>
                <div className='slider-wrapper'>
                    {items && props.orderList.length > 0 ?
                        <>
                            <h2 style={{ textAlign: 'center' }}>Popular Orders</h2>
                            <div className='row'>
                                {suggestedItems}
                            </div>
                        </>
                        : null}
                </div>
            </div>
    )
}
const mapStateToProps = state => {
    return {
        orderList: state.orderReducer.orderList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addItem: (...args) => dispatch(addItem(...args)),
        removeItem: (...args) => dispatch(removeItem(...args)),
        updateItem: (...args) => dispatch(updateItem(...args))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)