import React, { useEffect, useState } from 'react'
import { clearList } from './redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { useFormik } from 'formik'
import TextField from '@mui/material/TextField'
import * as Yup from 'yup'
import { Redirect } from 'react-router-dom'

function Checkout(props) {

    useEffect(() => {
        getItem()
    }, [])

    const [items, setItems] = useState()

    const getItem = () => {
        axios.get(`${props.api}/api/collections/get/products?token=${props.acctok}`)
            .then(res => {
                const current = res.data.entries
                setItems(current)
            })
            .catch(err => {
                console.log(err)
            })
    }

    let list, suggestedItems, calcPrice, subtotal

    if (items) {
        list = props.orderList.map(x => (
            x.id
        ))
    }

    if (items) {
        calcPrice = items.filter(x => list.includes(x._id)).map(x => (
            (x.sprice ? x.sprice : x.price) * props.orderList.find(c => c.id === x._id).quantity
        ))
        subtotal = calcPrice.reduce((a, b) => a + b, 0)
    }

    const phoneRegExp = /^\d\d\d-\d\d\d\d$/i

    const validationSchema = Yup.object({
        fname: Yup.string().required('Please enter your first name'),
        lname: Yup.string().required('Please enter your last name'),
        phone: Yup.string().matches(phoneRegExp, 'Not valid, please enter 7 digits only with a dash eg 299-1234, no spaces allowed.').required('Please enter your phone number'),
        email: Yup.string().email('Please enter a valid email address').required('Please enter your email address'),
    })

    const extraFields = { address: null, total: subtotal, processed: false, order: props.orderList }

    const formik = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            phone: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, onSubmitProps) => {
            const newData = { ...values, ...extraFields }
            const data = {
                data: {
                    ...newData
                }
            }
            axios.post(`${props.api}/api/collections/save/orders?token=${props.acctok}`, data)
                .then(res => {
                    if (res.status == 200) {
                        setSubmitted('success')
                        submittedMessage()
                    }
                })
                .catch(err => {
                    setSubmitted('failed')
                    submittedMessage()
                    console.log(err);
                })            
            onSubmitProps.resetForm()
            // props.clearList()

            // axios.post(`${props.api}/emailer/contactpost.php`, props.orderList)
            axios.post(`http://deecms.live/emailer/contactpost.php`, data)
                .then(res => {
                    // console.log(res.data)
                })
                .catch(err => {
                    console.log(err);
                })
        }
    })

    const checkOrderList = () => {
        const orderList = props.orderList
        let orderListCount = false
        if (orderList === undefined || orderList.length == 0) {
            orderListCount = true
        } else {
            orderListCount = false
        }
        return orderListCount
    }

    const [submitted, setSubmitted] = useState(null)

    const submittedMessage = () => {
        setTimeout(() => {
            setSubmitted(null)
            clearDux()
        }, 4000)
    }

    const clearDux = () => {
        setTimeout(() => {
            props.clearList()
        }, 2000)
    }

    return (
        (checkOrderList()) ? <Redirect to='/products' /> :
            <div className='checkout-page'>
                <h1 style={{ textAlign: 'center' }}>Place Order</h1>
                <form onSubmit={formik.handleSubmit}>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <TextField
                                fullWidth
                                name='fname'
                                label='First Name'
                                value={formik.values.fname}
                                onChange={formik.handleChange}
                                error={formik.touched.fname && Boolean(formik.errors.fname)}
                                helperText={formik.touched.fname && formik.errors.fname}
                                variant='standard'
                            />
                        </div>
                        <div className='col-12 col-md-6'>
                            <TextField
                                fullWidth
                                name='lname'
                                label='Last Name'
                                value={formik.values.lname}
                                onChange={formik.handleChange}
                                error={formik.touched.lname && Boolean(formik.errors.lname)}
                                helperText={formik.touched.lname && formik.errors.lname}
                                variant='standard'
                            />
                        </div>
                        <div className='col-12 col-md-6'>
                            <TextField
                                fullWidth
                                name='phone'
                                label='Phone'
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                variant='standard'
                                className='mt-2'
                            />
                        </div>
                        <div className='col-12 col-md-6'>
                            <TextField
                                fullWidth
                                name='email'
                                label='Email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                variant='standard'
                                className='mt-2'
                            />
                        </div>
                    </div>
                    <div className='text-center mt-4'>
                        <h2>Order Total: ${subtotal}</h2>
                    </div>
                    <div className='text-center mt-3'>
                        {submitted ?
                            submitted === 'success' ? <span className='sbt-success'>Submitted Successfully</span> : <span className='sbt-failure'>Error, please try again later</span> : null}
                    </div>
                    <div className='text-center mb-4'>
                        <button type='button' type='submit' className='btn btn-contact-message'>SUBMIT</button>
                    </div>
                </form>
            </div>
    )
}

const mapStateToProps = state => {
    return {
        orderList: state.orderReducer.orderList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearList: (...args) => dispatch(clearList(...args)),        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)