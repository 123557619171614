import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import axios from 'axios'
import Contact from './Contact'
import DesktopHeader from './DesktopHeader'
import DesktopHome from './DesktopHome'
import Footer from './Footer'
import Product from './Product'
import Products from './Products'
import ProductsList from './ProductsList'
import Cart from './Cart'
import About from './About'
import Checkout from './Checkout'
import PrivacyPolicy from './PrivacyPolicy'
import NotFound from './NotFound'
import ScrollToTop from './ScrollToTop'

class Home extends Component {
    constructor(props) {
        super(props)
        let width = window.innerWidth
        this.state = {
            // api: 'http://deecms.live',
            api: 'https://cms.deelightfultt.com',
            acctok: 'b2d4a65dd80de5ba62c89e4ad40a8e',
            categories: []
        }
    }

    componentDidMount() {
        axios.get(`${this.state.api}/api/collections/get/productCat?token=${this.state.acctok}`)
            .then(res => {
                this.setState({ categories: res.data.entries })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { categories } = this.state
        const routeLst = categories.map(cat => (
            <Route key={cat._id} path={`/${cat.name_slug}`} render={(props) => <ProductsList {...props} cat={cat} api={this.state.api} acctok={this.state.acctok} />} />
        ))

        return (
            <Router>
                <ScrollToTop>
                    <DesktopHeader api={this.state.api} acctok={this.state.acctok} />
                    <div className='container-fluid app-container'>
                        <Switch>
                            <Route exact path='/' render={(props) => <DesktopHome {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/our-story' render={(props) => <About {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/contact' render={(props) => <Contact {...props} api={this.state.api} acctok={this.state.acctok} css_class='contact-form' />} />
                            <Route exact path='/cart' render={(props) => <Cart {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/checkout' render={(props) => <Checkout {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/privacy-policy' render={(props) => <PrivacyPolicy {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/product/:id' render={(props) => <Product {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/products' render={(props) => <Products {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/not-found' component={NotFound} />
                            {/* <Route component={NotFound} /> */}
                            {routeLst}
                        </Switch>
                        <Footer api={this.state.api} acctok={this.state.acctok} />
                    </div>
                </ScrollToTop>
            </Router>
        )
    }
}

export default Home
