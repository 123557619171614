import React, { useEffect, useState } from 'react'
import axios from 'axios'

function PrivacyPolicy(props) {

    const [policy, setPolicy] = useState({
        policy: '',
    })

    const getPolicy = () => {
        axios.get(`${props.api}/api/singletons/get/privacyPolicy?token=${props.acctok}`)
            .then(res => {
                setPolicy({ policy: res.data.content })                
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getPolicy()
    }, [])

    return (
        <div className='privacy-page'>
            <h1 style={{textAlign: "center"}}>Privacy Policy for Dee-Lightful Productions Ltd</h1>
            <div dangerouslySetInnerHTML={{ __html: policy.policy }} />
        </div>
    )
}

export default PrivacyPolicy